import { Routes, Route, createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { PageIndex } from './modules/IndexPage';
import { PageMenuActual } from './modules/MenuActual';
import { PageMenuHelpful } from './modules/MenuHelpful';
import { PageMenuTools } from './modules/MenuTools';
import { PageMenuEmployee } from './modules/MenuEmployee';
import {Page404, PageError} from './modules/PageCodeState';
import { PageWorkInProgress } from './modules/PageCodeState';
import { PageMenuKnowlegde } from './modules/MenuLawFinance';
import { PageLawFinance } from './modules/PageLawFinance';
import { PageProfile } from './modules/ProfilePage';
import { PageProfileEdit } from './modules/ProfileEditPage';
import { PageNews } from './modules/NewsPage';
import { PageAchievements } from './modules/AchievementsPage';
import { PageMenuHandbook } from './modules/MenuHandbook';
import { HandbookOnboarding, HandbookPage } from './modules/HandbookPage';
import React, {useEffect, useState} from "react";
import {PageTools} from "./modules/ToolsPage";
import WithAuth from "./modules/auth/WithAuth";
import {PageTeam} from "./modules/TeamPage";
import {PageStructure} from "./modules/StructurePage";
import {QuestionStart, Quiz, ResultQuestion} from "./modules/Elements";
import { ErrorBoundary } from "react-error-boundary";

function Root() {

    const [firstOpen, setFirstOpen] = useState(false);

    const tg = window.Telegram.WebApp;
    useEffect(() => {
        if(firstOpen === false){
            setFirstOpen(true);
        }
    }, []);

    useEffect(() => {
        try{
            tg.expand();

        } catch(e){}
    }, [firstOpen]);

    useEffect(() => {
        window.addEventListener("beforeunload", (ev) =>
        {
            fetch(`/api/post/TelegramSession?session_key=${localStorage.getItem("session_key")}`, {
                method: 'POST',
                body: JSON.stringify({
                    session_is_active: false,
                }),
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("access_token")}`
                }
            }).then(r => {});
        });
    }, [localStorage.getItem("session_key")]);

    useEffect(() => {
        if (localStorage.getItem("session_user") === null){
            //window.location.replace('https://google.com');
        }
    }, [localStorage.getItem("session_user")]);

    return (
            <Routes>
                <Route path="/" element={<WithAuth><PageIndex /></WithAuth>} />
                <Route path="actual" element={<PageMenuActual />} />
                <Route path="helpful" element={<PageMenuHelpful />} />
                <Route path="helpful/tools" element={<PageMenuTools />} />
                <Route path="helpful/employers" element={<WithAuth><PageMenuEmployee /></WithAuth>} />
                <Route path="helpful/knowledge" element={<PageMenuKnowlegde />} />
                <Route path="helpful/help" element={<PageWorkInProgress />} />
                <Route path="helpful/handbook" element={<PageMenuHandbook />} />
                <Route path="helpful/handbook/about" element={<HandbookPage block={1}/>} />
                <Route path="helpful/handbook/structure" element={<HandbookPage block={2}/>} />
                <Route path="helpful/handbook/referral" element={<HandbookPage block={3}/>} />
                <Route path="helpful/handbook/worth" element={<HandbookPage block={4}/>} />
                <Route path="helpful/handbook/work_format" element={<HandbookPage block={5}/>} />
                <Route path="helpful/handbook/vacation" element={<HandbookPage block={6}/>} />
                <Route path="helpful/handbook/growth" element={<HandbookPage block={7}/>} />
                <Route path="Users" element={<PageProfile />} />
                <Route path="Users/achievements" element={<PageAchievements />} />
                <Route path="Users/edit" element={<PageProfileEdit />} />
                <Route path="News" element={<PageNews />} />
                <Route path="Tools" element={<PageTools />} />
                <Route path="LawFinance" element={<PageLawFinance />} />
                <Route path="Team" element={<PageTeam />} />
                <Route path="Structure" element={<PageStructure />} />
                <Route path="error" element={<PageError error={''}/>} />
                <Route path="onboarding/start" element={<QuestionStart/>}/>
                <Route path="onboarding/handbook/:block" element={<HandbookOnboarding/>}/>
                <Route path="onboarding/question/:block" element={<Quiz/>}/>
                <Route path="onboarding/end" element={<ResultQuestion/>}/>
                <Route path="*" element={<Page404 />} />
            </Routes>
    );
}
const ErrorBoundaryLayout = () => (
    <ErrorBoundary FallbackComponent={PageError}>
        <Outlet />
    </ErrorBoundary>
);

const router = createBrowserRouter([
    {element: <ErrorBoundaryLayout />, children:[{path: "*", element: <Root /> }]},
]);

export default function App(){
    return <RouterProvider router={router}/>
}
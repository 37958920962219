// IMPORTING STYLES
import '../css/codeState.min.css';
import '../css/index.min.css';
// IMPORTING ELEMENTS
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

function PageProgressBlock({text, image}) {
    return(
        <div className="page404-container">
            <img className="page404-container-image" src={image} alt="404"/>
            <div className="page404-container-description">
                <p>{text}</p>
            </div>
        </div>
    )
}


export function Page404({title}) {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate();
    useEffect(() => {
        document.title = title ? title : 'Страница не найдена';
        tg.BackButton.show();
        tg.BackButton.onClick((() => navigate(document.referrer.replace(/^[^:]+:\/\/[^/]+/, '').replace(/#.*/, '').replace(/\?.*/, ''))));
        tg.BackButton.show();
        tg.MainButton.isVisible = true;
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
    }, []);
    return(
        <PageProgressBlock text="В процессе разработки!" image="/src/images/icons/work_in_progress.svg"/>
    );
}

export function PageWorkInProgress() {
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = 'В процессе разработки';
        tg.BackButton.show();
        tg.MainButton.isVisible = true;
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        tg.BackButton.show();
        tg.BackButton.onClick((() => window.location.assign(document.referrer.replace(/^[^:]+:\/\/[^/]+/, '').replace(/#.*/, ''))));
    }, []);
    return(
        <PageProgressBlock text="Похоже, ничего не найдено!" image="/src/images/icons/404.svg"/>
    );
}

export function PageError({ error, resetErrorBoundary }) {
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        console.log(error);
        document.title = 'Ошибка!';
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        tg.MainButton.setText("Написать разработчику")
        tg.MainButton.isVisible = true;
        tg.MainButton.onClick(() => window.location.assign('https://t.me/n1ghtcore194'));
        tg.BackButton.show();
        tg.BackButton.onClick((() => window.location.assign(document.referrer.replace(/^[^:]+:\/\/[^/]+/, '').replace(/#.*/, ''))));
    }, []);
    return(
        <PageProgressBlock text={`Произошла ошибка! Ошибка - ${error.message ? error.message : "authError"} Напишите об ошибке в телеграм @n1ghtcore194`} image="/src/images/icons/404.svg"/>
    );
}